
    
<template>
<div>
    <b-row>
        <b-col cols="12">
   
  <b-list-group>
    <b-list-group-item v-for="(item,index) in items" :key="item.id.toString()">
        {{item.arabicName}}
        <b-form-checkbox
           :id="index.toString()"
           v-model="selected"
           :value="item"
           style="float: left"
           
          />
        </b-list-group-item>
   
  </b-list-group>
  </b-col>
   </b-row>
<b-row >
    <b-col cols="8" >
        <div style="margin-top:5vh">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"

                type="submit"
                variant="primary"
                class="mr-1"
                @click="UpdateItems()"
              >
                إضافه
              </b-button>
            
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="back"
                variant="primary"
                style="margin-right:15px;"
                @click="this.back"
              >
                رجوع
              </b-button>
              </div>
            </b-col>
            </b-row>
            </div>
</template>

<script>
import { BListGroup, BListGroupItem,BFormCheckbox,BButton,BRow,BCol } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BButton,
    BRow,BCol
  },
  directives: {
    Ripple,
  },

  props:['id'],
 
  created(){
    this.GetReportItems();
    this.GetCompanyReportItems();
  },
  data(){
    return{
        items:[],
        selected:[],
        companyReportItems:null
    }
    },
    methods:{
      async GetReportItems(){
        try {
        var response = await this.$store.dispatch('GetReportItems');
       if(response.status = 200){
       this.items = response.data
     }
      } 
      catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title:  'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
              icon: 'TrashIcon',
              variant: 'danger',
            },
          })
      }
      },

       async GetCompanyReportItems(){
        try {
        var response = await this.$store.dispatch('GetCompanyReportItems',this.$route.params.id);
      if(response.status = 200){
       this.selected = response.data
     }
      } 
      catch (err) {
          this.$toast({
           
            component: ToastificationContent,
            props: {
              title:  'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
              icon: 'TrashIcon',
              variant: 'danger',
            },
          })
      }
      },
      async UpdateItems(){
  
  this.selected = this.selected.map(i=>({"ReportItemId":i.id}))
            const payload = {
              companyId : this.$route.params.id,
              CompanyReportItems:this.selected
            }
             try {
        var response = await this.$store.dispatch('AddReportItemToCompany',payload);
      if(response.status = 200){
         this.$toast({
            component: ToastificationContent,
            props: {
              title: 'تم تعديل البيانات بنجاح',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
       this.$router.push({name:'companies'})

     }
      } 
      catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title:  'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
              icon: 'TrashIcon',
              variant: 'danger',
            },
          })
      }
        },
        back(){
            this.$router.push({name:'companies'})

        }
    },

  }

</script>